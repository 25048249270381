div.tambahBarang > h5.sectionHeading {
  margin-bottom: 0.5em;
}

div.tambahBarang > form > div {
  display: flex;
  flex-wrap: nowrap;
}

div.tambahBarang > form > div > div {
  position: relative;
  margin-right: 1em;
}

div.tambahBarang > form > div > div:last-of-type {
  margin-right: 0;
}

div.tambahBarang > form > div > div > label::before {
  position: absolute;
  font-size: 18px;
  top: 10px;
  left: 0;
  display: block;
  content: "";
  width: 100%;
  height: 0.5em;
  background-color: rgba(239, 239, 239, 0.2);
  z-index: -1;
}

div.tambahBarang > form > div > div > label::after {
  position: absolute;
  font-size: 18px;
  top: 0;
  left: 0;
  display: block;
  content: "";
  width: 100%;
  height: calc(0.5em + 2px);
  background-color: rgba(239, 239, 239, 0.1);
  z-index: -1;
}

div.tambahBarang > form > div > div > label {
  display: inline-block;
  font-size: 18px;
  position: absolute;
  height: 1em;
  top: 0.5em;
  background-color: white;
  margin-left: 11px;
  padding: 0 10px;
  color: var(--c-secondary);
  z-index: 10;
  width: max-content;
}

div.tambahBarang > form > div > div > label > .reqStar {
  color: red;
}

div.tambahBarang > form > div > div > input {
  width: 100%;
  border: 1px solid var(--c-secondary);
  font-size: 18px;
  padding: 0.8em 20px 0.6em 20px;
  border-radius: 0.4em;
  color: black;
}

div.tambahBarang > form > div > div > input:disabled {
  background-color: rgba(239, 239, 239, 0.3);
  font-style: italic;
  color: #555;
}

div.tambahBarang > form > div > div > input:focus {
  outline: none;
}

div.tambahBarang .customOperationMessage {
  margin-top: 1em;
  margin-bottom: 1em;
}

button.cancelAddProductBtn {
  background-color: #bf0000;
  width: max-content;
  margin: 0;
  margin-top: 18px; 
  padding-left: 1.2em;
  padding-right: 1.2em;
}

button.addProductBtn {
  background-color: green;
  width: max-content;
  margin: 0;
  margin-top: 18px; 
  padding-left: 1.2em;
  padding-right: 1.2em;
}

.inputError > input {
  border-color: #e80000 !important;
}

.inputError > label {
  color: #e80000 !important;
}