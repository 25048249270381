.laporanPenjualan .cetakStockCheckBtn {
  margin-bottom: 0;
  margin-left: 2em;
  border-radius: 24px;
  box-shadow: 0px 2px 2px 0px #aaa;
}

.laporanPenjualan .sortDiv {
  margin-left: auto;
  font-size: 1.2em;
}

.laporanPenjualan .customToggler {
  height: 100%;
  display: inline-block;
  margin-left: 1em;
}

.laporanPenjualan .customToggler > button {
  height: 100%;
}

.laporanPenjualan .controls {
  display: flex;
  margin-top: 1em;
  margin-bottom: 1em;
}

.laporanPenjualan .printContainer h3.tabHeading {
  font-size: 1.8em;
}

.laporanPenjualan .controls > :not(form) {
  margin-top: 0.4em;
}

.laporanPenjualan .cetakStockCheckBtn {
  margin-bottom: 0;
  margin-left: 2em;
  border-radius: 24px;
  box-shadow: 0px 2px 2px 0px #aaa;
}

.laporanPenjualan .customToggler {
  height: 100%;
  display: inline-block;
  margin-left: 1em;
}

.laporanPenjualan .customToggler > button {
  min-height: 100%;
}

.laporanPenjualan .laporanDataDiv {
  padding: 2em;
  border: 1px solid var(--c-secondary);
  border-radius: 0.5em;
}

.laporanPenjualan .dataTitle {
  font-size: 1.6em;
  font-weight: 500;
  margin-left: -1px;
}