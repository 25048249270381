.laporanPembelian .cetakStockCheckBtn {
  margin-bottom: 0;
  margin-left: 2em;
  border-radius: 24px;
  box-shadow: 0px 2px 2px 0px #aaa;
}

.laporanPembelian .sortDiv {
  margin-left: auto;
  font-size: 1.2em;
}

.laporanPembelian .customToggler {
  height: 100%;
  display: inline-block;
  margin-left: 1em;
}

.laporanPembelian .customToggler > button {
  height: 100%;
}

.laporanPembelian .controls {
  display: flex;
  margin-top: 1em;
  margin-bottom: 1em;
}

.laporanPembelian .printContainer h3.tabHeading {
  font-size: 1.8em;
}

.laporanPembelian .laporanDataDiv {
  padding: 2em;
  border: 1px solid var(--c-secondary);
  border-radius: 0.5em;
}

.laporanPembelian .dataTitle {
  font-size: 1.6em;
  font-weight: 500;
  margin-left: -1px;
}

.laporanPembelian .searchWrapper {
  position: relative;
}

.laporanPembelian .searchInput {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid var(--c-secondary);
  border-radius: 0.5rem;
  font-size: 1rem;
}

.laporanPembelian .searchInput:focus {
  outline: none;
}

.laporanPembelian .productView {
  width: 100%;
}

.laporanPembelian .productView .dataTitle {
  line-height: 44.25px;
}