.tambahPenjualan .loadingBlock {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: white;
}

.mainContent:has(.loadingBlock) {
  overflow: hidden;
}

.tambahPenjualan .loadingBlock .lds-dual-ring {
  position: relative;
  top: 50%;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
}

.tambahPenjualan .loadingBlock .lds-dual-ring::after {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.tambahPenjualan > form .addCustomerBtn, .tambahPenjualan > form .verifyCustomerBtn {
  font-size: 18px;
  padding: 0.6em 1.2em;
  line-height: 27.6px;
}

.tambahPenjualan > form .verifyCustomerBtn {
  background-color: green;
}

.addCustomerCard {
  width: min(800px, 80vw);
}

.addCustomerCard .cardButtons {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  margin-top: 3em;
  margin-bottom: 0.5em;
}

.addCustomerCard .cardButtons .tambahCustomer-cancel {
  background-color: #bf0000;
}

.addCustomerCard .cardButtons .tambahCustomer-confirm {
  background-color: green;
}

.addCustomerCard .customOperationMessage {
  margin-top: 1.5em;
}

.tambahPenjualan > .customOperationMessage {
  margin-bottom: 0.5em;
}

.tambahPenjualan .customTable .tableRow input.tableProductInput {
  font-size: 16px;
  width: 100%;
  border: none;
  padding: 0.5em 0;
}

.tambahPenjualan .customTable .tableRow input.tableProductInput::placeholder {
  color: #aaa;
}

.tambahPenjualan .customTable .tableRow input.tableProductInput:focus {
  outline: none;
}

.printResiBtn {
  background-color: var(--c-primary);
}

.submitpenjualanBtn, .closeSaleSuccessBtn {
  background-color: green;
}

.tambahPenjualan .customTable .tableRow .selectable {
  border: 1px solid var(--c-secondary);
  border-radius: 0.4em;
}

.tambahPenjualan .customTable .tableRow .selectable > input.tableProductInput {
  padding: 0.5em 0.75em;
  background-color: transparent;
}

.tambahPenjualan .selectable::after {
  position: absolute;
  top: 62.5%;
  right: 1.5em;
  content: "";
  width: 8px;
  height: 8px;
  border-left-width: 0;
  border-top-width: 0;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-style: solid;
  border-color: var(--c-secondary);
  transform: translateY(-60%) rotate(45deg);
}

.tambahPenjualan .customTable .tableRow .selectable::after {
  width: 6px;
  height: 6px;
  top: 50%;
  right: 0.75em;
  transform: translateY(-60%) rotate(45deg);
}

.verifyCustomerDiv i.bi {
  font-size: 20px;
  position: absolute;
  top: calc(25px + 18px);
  transform: translateY(-50%);
  right: 1em;
}

.verifyCustomerDiv i.bi.bi-check-circle {
  color: green;
}

.verifyCustomerDiv i.bi.bi-x-circle {
  color: red;
}

.tambahPenjualan .customTable .tableRow div.editable input.tableProductInput {
  border: 1px solid var(--c-secondary);
  border-radius: 0.4em;
  padding: 0.5em 0.75em;
  background-color: transparent;
}

.tambahPenjualan .customTable .tableRow.discountRow {
  border-bottom: 0;
}

.tambahPenjualan .customTable .tableRow.discountRow div.editable input {
  border-bottom: 0;
  border: 1px solid var(--c-secondary);
  border-radius: 0.4em;
  padding: 0.5em 0.75em;
  background-color: transparent;
  font-size: 16px;
  width: 100%;
}

.tambahPenjualan .customTable .tableRow.endingRow {
  padding-top: 0;
}

.tambahPenjualan .resetPenjualanBtn {
  background-color: #ef0000;
}

.tambahPenjualan div.online-order h5.form-title {
  margin-bottom: 1em;
  font-weight: 500;
}

.tambahPenjualan div.online-order .confirm-onlineNo-btn {
  background-color: green;
}
.tambahPenjualan div.online-order .cancel-onlineNo-btn {
  background-color: #ef0000;
}

.tambahPenjualan h5 span.online-order-label {
  font-size: 0.8em;
  margin-right: 0.5em;
  color: #555;
}