.customTable {
  padding: 2em;
  border: 1px solid var(--c-secondary);
  border-radius: 0.5em;
}

.tableScroll {
  max-height: calc(100vh - 320px);
  overflow-y: auto;
  /* padding-right: 0.5em; */
  position: relative;
}

.tableScroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.tableScroll::-webkit-scrollbar-track:vertical {
  margin-top: 44.6px;
  background-color: transparent;
}

.customTable.dataOnly {
  padding: 0;
  border: 0;
  border-radius: 0;
}

.tableRow {
  display: flex;
  justify-content: space-between;
  gap: 0.75em;
}

.tableRow .customTable-columnData {
  padding: 0.5em 0em;
}

.tableRow ~ .tableRow .customTable-columnData {
  display: flex;
  align-items: center;
  padding: 0.8em 0em;
}

.tableRow ~ .tableRow {
  border-bottom: 1px solid #0f736650;
}

.tableRow ~ .tableRow .customTable-columnData * {
  flex-grow: 1;
}

.tableRow:last-of-type {
  border-bottom: none;
}

.tableRow.endingRow:last-of-type {
  padding: 0.8em 0em;
}

.tableRow:nth-of-type(1) {
  border-bottom: 1px solid var(--c-secondary, #0f7366);
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 10;
}

.tableRow:nth-of-type(1) .customTable-columnData {
  padding: 0.8em 0em;
}

.noDataError {
  text-align: start;
  padding: 2em 0em;
}

