.tambahpembelian > form .addSupplierBtn {
  font-size: 18px;
  margin-bottom: 0;
  padding: 0.6em 1.2em;
  line-height: 27.6px;
}

.addSupplierCard {
  width: min(600px, 80vw);
}

.addSupplierCard input {
  width: 100%;
  border: 1px solid var(--c-secondary);
  font-size: 18px;
  padding: 0.8em 20px 0.6em 20px;
  border-radius: 0.4em;
  color: black;
}

.addSupplierCard input:focus {
  outline: none;
}

.addSupplierCard > form > div > label {
  display: inline-block;
  font-size: 18px;
  position: absolute;
  height: 1em;
  top: 0.5em;
  background-color: white;
  margin-left: 11px;
  padding: 0 10px;
  color: var(--c-secondary);
  z-index: 10;
  width: max-content;
}

.addSupplierCard > form > div > label > .reqStar {
  color: red;
}

.addSupplierCard .cardButtons {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  margin-top: 3em;
  margin-bottom: 0.5em;
}

.addSupplierCard .cardButtons .tambahSupplier-cancel {
  background-color: #bf0000;
}

.addSupplierCard .cardButtons .tambahSupplier-confirm {
  background-color: green;
}

.addSupplierCard .customOperationMessage {
  margin-top: 1.5em;
}

.tambahpembelian > .customOperationMessage {
  margin-bottom: 0.5em;
}

.tambahpembelian .customTable .tableRow input.tableProductInput {
  font-size: 16px;
  width: 100%;
  border: none;
  padding: 0.5em 0;
}

.tambahpembelian .customTable .tableRow .selectable {
  border: 1px solid var(--c-secondary);
  border-radius: 0.4em;
}

.tambahpembelian .customTable .tableRow .selectable > input.tableProductInput {
  padding: 0.5em 0.75em;
  background-color: transparent;
}

.tambahpembelian .customTable .tableRow .selectable::after {
  position: absolute;
  top: 50%;
  right: 0.8em;
  content: "";
  width: 6px;
  height: 6px;
  border-left-width: 0;
  border-top-width: 0;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-style: solid;
  border-color: var(--c-secondary);
  transform: translateY(-60%) rotate(45deg);
}

.tambahpembelian .customTable .tableRow input.tableProductInput::placeholder {
  color: #aaa;
}

.tambahpembelian .customTable .tableRow input.tableProductInput:focus {
  outline: none;
}

.submitPembelianBtn {
  background-color: green;
}

.tambahpembelian .customTable .tableRow div.editable input.tableProductInput {
  border: 1px solid var(--c-secondary);
  border-radius: 0.4em;
  padding: 0.5em 0.75em;
  background-color: transparent;
}

.tambahpembelian .purchase-tooltip {
  width: max-content;
  position: absolute;
  background-color: white;
  border-radius: 0.3em;
  box-shadow: 1px 1px 8px 0 #777;
  padding: 1em;
  top: 50%;
  left: 0;
  transform: translateX(calc(-100% - 1em)) translateY(-50%);
  transition: opacity 0.1s ease-in-out;
  z-index: 1000;
}