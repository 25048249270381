.customOperationMessage {
  font-size: 16px;
  font-weight: 400;
  color: white;
  padding: 1.2em 2em;
  border-radius: 0.4em;
  overflow: hidden;
  height: 100%;
  transition: height 0.5s ease, padding 0.5s ease, margin 0.5s ease;
}

.customOperationMessage.hideOperationMessage {
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0 !important;
}

.customOperationMessage.hideOperationMessage .operationMessageWrapper {
  display: none;
}

.customOperationMessage.successMessage {
  background-color: green;
}

.customOperationMessage.errorMessage {
  background-color: red;
}