.searchContainer {
  position: absolute;
  min-width: min-content;
  width: 100%;
  background-color: white;
  z-index: 80;
  margin-top: 0.5em;
  border: 1px solid var(--c-secondary);
  border-radius: 0.4em;
}

.searchContainer > div.searchListing {
  position: relative;
  padding: 0.75em 1.25em;
  cursor: pointer;
}

.searchContainer > div.searchListing::before {
  position: absolute;
  top: 100%;
  left: 0.75em;
  content: "";
  width: calc(100% - 1.5em);
  height: 1px;
  background-color: var(--c-secondary);
  display: block;
  z-index: 100;
}

.searchContainer > div.searchListing:last-of-type::before {
  display: none;
}

div.searchListing.selectedListing {
  background-color: #eee;
}

.searchContainer > div.searchListing:first-of-type:hover, div.searchListing:first-of-type.selectedListing {
  border-radius: 0.4em 0.4em 0 0;
}

.searchContainer > div.searchListing:last-of-type:hover, div.searchListing:last-of-type.selectedListing {
  border-radius: 0 0 0.4em 0.4em;
}

.searchContainer > div.searchListing:only-of-type:hover, div.searchListing:only-of-type.selectedListing {
  border-radius: 0.4em 0.4em 0.4em 0.4em;
}