.mainInterface {
  width: 100vw;
  height: 100vh;
  animation: fadein 0.2s;
}

.mainContent {
  position: relative;
  top: 100px;
  left: calc(60px + 1.6em);
  width: calc(100vw - (60px + 1.6em));
  height: calc(100vh - 100px);
  overflow-y: scroll;
  overflow-x: auto;
}

.mainContent > div {
  padding: 3rem 3rem 3rem 3rem;
}

.mainContent > * {
  animation: fadein 0.2s;
}

h3.tabHeading {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 1em;
}

h5.sectionHeading {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 1em;
}

hr {
  border: 0;
  border-top: 1px solid var(--c-secondary);
}

form > div:has(div) {
  display: flex;
}

form > div:has(div):not(:last-of-type) {
  margin-bottom: 1.2em;
}

form div {
  position: relative;
}

form div:has(> input) {
  margin-top: -18.5px;
  margin-right: 1em;
}

form > div > div:last-of-type {
  margin-right: 0;
}

/* form div > label::before {
  position: absolute;
  font-size: 18px;
  top: 10px;
  left: 0;
  display: block;
  content: "";
  width: 100%;
  height: 0.5em;
  background-color: rgba(239, 239, 239, 0.2);
  z-index: -1;
}

form div > label::after {
  position: absolute;
  font-size: 18px;
  top: 0;
  left: 0;
  display: block;
  content: "";
  width: 100%;
  height: calc(0.5em + 2px);
  background-color: rgba(239, 239, 239, 0.1);
  z-index: -1;
} */

form div > label {
  display: inline-block;
  font-size: 18px;
  position: absolute;
  height: 1em;
  top: 0.5em;
  margin-left: 11px;
  padding: 0 10px;
  color: var(--c-secondary);
  z-index: 10;
  width: max-content;
}

form div > label::before {
  position: absolute;
  font-size: 18px;
  top: 9px;
  left: 0;
  display: block;
  content: "";
  width: 100%;
  height: 3px;
  background-color: white;
  z-index: -1;
}

form div:has(input[disabled]) > label::before {
  background-color: #fcfcfc;
}

form div > label > .reqStar {
  color: red;
}

form div > input {
  width: 100%;
  border: 1px solid var(--c-secondary);
  font-size: 18px;
  padding: 0.8em 20px 0.6em 20px;
  border-radius: 0.4em;
  color: black;
}

form div > input:focus {
  outline: none;
}

button {
  transition: filter 0.2s ease;
}

button:hover {
  filter: brightness(1.15);
}