.Dropdown {
  font-size: 18px;
  position: relative;
}

.Dropdown .selector {
  padding: 0.6em 1.25em;
  border-radius: 0.4em;
  border: 1px solid #555;
  transition: background-color 0.2s ease, color 0.2s ease;
  cursor: pointer;
  user-select: none;
  background-color: white;
  color: black;
}

.Dropdown.selected .selector, .Dropdown .selector:hover {
  background-color: #555;
  color: white;
}

.Dropdown .content-wrapper {
  display: none;
  position: absolute;
  z-index: 100;
  background-color: white;
  border-radius: 0.4em;
  padding: 1.6em 1.6em 1.8em 1.6em;
  top: calc(100% + 0.5em);
  box-shadow: 0 2px 0.75em rgba(0, 0, 0, 0.4);
}

.Dropdown.selected .content-wrapper {
  display: block;
}