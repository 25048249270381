.errorPage {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #e8e8e8;
  animation: fadein 0.5s;
  overflow-y: scroll;
}

.error-BoxWrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 3em;
  border: 1px solid block;
  box-shadow: 0px 2px 4px 0px #888;
  width: min(450px, 90vw);
  height: min-content;
  border-radius: 0.5em;
}

.error-BoxWrapper img {
  width: 100%;
}

.error-BoxWrapper h3 {
  margin-top: 1em;
  margin-bottom: 0.8em;
  font-size: 20px;
}

.error-BoxWrapper .error-text {
  text-align: start;
  margin-bottom: 1.2em;
}

.error-BoxWrapper .error-contact {
  text-decoration: underline;
}

.error-BoxWrapper button {
  display: block;
  border: none;
  font-size: 16px;
  padding: 0.8em 1.2em;
  background-color: #005da4;
  color: white;
  border-radius: 0.3em;
  box-shadow: 0px 2px 3px 0px #777;
  cursor: pointer;
  user-select: none;
}

.error-BoxWrapper i.bi { 
  position: relative;
  top: 1px;
  margin-right: 0.5em;
}

.error-BoxWrapper button > span { 
  position: relative;
}