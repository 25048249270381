.popupWindowSection {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  z-index: 15000;
}

.popupWindowGray {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0, 0.5);
  z-index: 200
}

.popupWindowSection > div:nth-of-type(2) {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 2em;
  background-color: white;
  border-radius: 0.5em;
  transform: translate(-50%, -50%);
  box-shadow: 0px 2px 12px 0px #333 ;
  z-index: 500;
  min-width: 600px;
  max-width: calc(100vw - 100px);
}

.popupWindowSection > div:nth-of-type(2) > .cardHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
}

.popupWindowSection > div:nth-of-type(2) h5 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0.5em;
}

.popupWindowSection > div:nth-of-type(2) h6 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0.5em;
}

.messagePopup h4 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0.5em;
}

.messagePopup p {
  margin-bottom: 0.8em;
  line-height: 1.6em;
}

.messagePopup .messagePopup-ack {
  background-color: green;
}