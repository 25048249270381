.customToggler > button {
  font-size: 18px;
  padding: 0.6em 1.25em;
  border-style: solid;
  border-radius: 0;
  border-width: 1px 0 1px 0;
  cursor: pointer;
  background-color: white;
  font-weight: 400;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.customToggler > button.selected {
  background-color: #555;
  color: white;
}

.customToggler > button:hover {
  filter: inherit;
  background-color: #555;
  color: white;
}

.customToggler.togglerSecondaryColor > button {
  border-color: var(--c-secondary);
}

.customToggler.togglerSecondaryColor > button.selected {
  background-color: var(--c-secondary);
  color: white;
}

.customToggler.togglerSecondaryColor > button:hover {
  filter: inherit;
  background-color: var(--c-secondary);
  color: white;
}

.customToggler > button:first-of-type {
  border-width: 1px 0 1px 1px;
  border-radius: 0.4em 0 0 0.4em;
}

.customToggler > button:last-of-type {
  border-width: 1px 1px 1px 0;
  border-radius: 0 0.4em 0.4em 0;
}