.customerTab form > div {
  align-items: flex-end;
}

.customerTab .headingFlex {
  display: flex;
  align-items: center;
  margin-bottom: 2.25rem;
}

.customerTab .headingFlex * {
  margin-bottom: 0;
}

.customerTab form button {
  margin-bottom: 1px;
}

.customerTab .addCustomerBtn {
  margin-bottom: 0;
  margin-left: 2em;
  border-radius: 24px;
  box-shadow: 0px 2px 2px 0px #aaa;
}

.customerTab .searchCustomerBtn {
  background-color: green;
}

.customerTab .customer-data-section {
  margin-top: 2em;
}

.customerTab .customer-data-section h3 {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 16px;
}

.customerTab .customer-data-table {
  display: flex;
  gap: 2em;
  align-items: center;
  max-width: 800px;
  padding: 2em;
  border: 1px solid var(--c-secondary);
  border-radius: 0.5em;
  
}

.customerTab .customer-data-table .bi.bi-person-circle {
  display: block;
  font-size: 200px;
  line-height: 1em;
  color: #555;
}

.customerTab .customer-data-table-info {
  flex-grow: 1;
}

.customerTab .customer-data-table-info .customerNameStrip {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.customerTab .customer-data-table-info .customerNameStrip * {
  line-height: 1em;
}

.customerTab .customer-data-table-info .customerNameStrip .badge {
  display: none;
  margin: 0 0 0 1.6em;
  padding: 0.4em 1.5em;
  border-radius: 1em;
}

.customerTab .r .customer-data-table-info .customerNameStrip .regular-badge {
  display: block;
  background-color: #999;
  color: white;
}

.customerTab .v .customer-data-table-info .customerNameStrip .vip-badge {
  display: block;
  background-color: goldenrod;
  color: white;
}

.customerTab .customer-data-table-info .customerName {
  font-size: 30px;
  font-weight: 500;
  margin-top: 1px;
  margin-bottom: 0;
}

.customerTab .v .customer-data-table-info .customerName {
  color: goldenrod;
}

.customerTab .customer-data-table-info .customerInfoStrip {
  display: flex;
}

.customerTab .customer-data-table-info .customerInfoStrip .infoStripSeparator {
  margin: 0 0.6em;
}

.customerTab .customer-data-table-info hr {
  margin-top: 1em;
  margin-bottom: 1.6em;
  border-top: 3px solid #555;
}

.customerTab .customer-data-table-info .customerInfoDescriptor {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0.3em;
}