/* .kelolaproduk .customTable > .tableRow {
  position: relative;
}
 */
div.productHeadingFlex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1em;
}

input#searchProductsInput {
  border: 1px solid var(--c-secondary);
  font-size: 18px;
  padding: 0 20px;
  border-radius: 0.4em;
  color: black;
}

input#searchProductsInput:focus, input#edit_s_price:focus {
  outline: none;
}

i.bi.bi-pencil-square, i.bi.bi-toggle-on, i.bi.bi-toggle-off {
  cursor: pointer;
}

.kelolaproduk .editProductCard {
  width: min(max(800px, 70%), 1000px);
}

.kelolaproduk .editProductCard > form > .cardButtons {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  margin-top: 3em;
  margin-bottom: 0.5em;
}

.kelolaproduk .editProduct-cancel, .editProduct-confirm {
  margin: 0;
  padding: 0.5em 1.2em;
}

.kelolaproduk .editProduct-cancel {
  background-color: #bf0000;
}

.kelolaproduk .editProduct-confirm {
  background-color: green;
}

.kelolaproduk .openAddProductBtn, .cetakStockCheckBtn {
  margin-bottom: 0;
  border-radius: 24px;
  box-shadow: 0px 2px 2px 0px #aaa;
}

.kelolaproduk .tabHeading {
  margin-bottom: 0;
}

.kelolaproduk .controlFlex {
  display: flex;
  margin: 1em 0 2em 0;
}

.kelolaproduk .openAddProductBtn {
  margin-right: 0.75em;
}

.kelolaproduk .cetakStockCheckBtn {
  margin-left: 0 ;
}

.kelolaproduk .tableNavOption {
  background-color: var(--c-secondary);
  color: white;
  transition: filter 0.2s ease;
}

.kelolaproduk .tableNavOption:hover {
  background-color: var(--c-secondary);
  color: white;
  filter: brightness(1.2);
}

.kelolaproduk .item-tooltip {
  width: max-content;
  position: absolute;
  background-color: white;
  border-radius: 0.3em;
  box-shadow: 1px 1px 8px 0 #777;
  padding: 1em;
  top: 50%;
  left: 0;
  transform: translateX(calc(-100% - 1em)) translateY(-50%);
  transition: opacity 0.1s ease-in-out;
  font-size: 16px;
  max-width: 400px;
  z-index: 100;
}

.kelolaproduk .item-tooltip p.tooltip-title {
  font-weight: 500;
  margin-bottom: 0.3em;
}

.kelolaproduk .item-tooltip p.tooltip-content {
  color: #383838;
  text-transform: uppercase;
}