.loginPage {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #e8e8e8;
  animation: fadein 0.5s;
  overflow-y: scroll;
}

.login-formWrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 3em;
  border: 1px solid block;
  box-shadow: 0px 2px 4px 0px #888;
  width: min(450px, 90vw);
  height: min-content;
  border-radius: 0.5em;
}

.login-formWrapper img {
  width: 100%;
}

.login-formWrapper h3 {
  margin-top: 1.6em;
  margin-bottom: 0.2em;
  font-size: 32px;
}

.login-formWrapper form label {
  display: inline-block;
  margin-top: 1em;
  margin-bottom: 0.3em;
  font-size: 18px;
}

.login-formWrapper form input[name="username"], 
.login-formWrapper form input[name="password"] {
  width: 100%;
  font-size: 20px;
  padding: 0.5em;
  border-radius: 0.3em;
}

.loginErrorMsg {
  margin: 0.5em 0em;
  color: red;
}

.criticalErrorMsg {
  font-weight: 600;
  text-decoration: underline;
  margin-top: 2em;
  color: red;
}

.loginSubmit-flex {
  display: flex;
  margin-top: 4em;
  margin-bottom: 1em;
}

.lds-dual-ring {
  display: block;
  width: 36px;
  height: 36px;
  margin: 6px 1.5em;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 5px solid;
  border-color: var(--c-secondary) transparent var(--c-secondary) transparent;
  animation: lds-dual-ring 0.5s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
