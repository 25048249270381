.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(60px + 1.6em);
  height: 100vh;
  background-color: var(--c-secondary, #0f7366);
  /* box-shadow: 0px 0px 10px 0px #555; */
  padding: 2em 0.8em;
  transition: width 0.5s ease 0s, padding 0.5s ease 0s;
  z-index: 10000;
}

.sidebar.open {
  padding: 2em 2em;
  width: max(20%, 400px);
}

.sidebarContent {
  max-height: calc(100vh - 2em);
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  user-select: none;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.sidebarContent::-webkit-scrollbar {
  display: none;
}

@media screen and (max-height: 599px) {
  .sidebarContent {
    justify-content: flex-start;
  }
}

.sidebarContent > * {
  overflow-x: hidden;
}

.sidebar-mt {
  flex-shrink: 0;
}

.sidebar-mt ~ .sidebar-mt {
  overflow: visible;
  margin-top: 0.8em;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.sidebar-mt::-webkit-scrollbar {
  display: none;
}

.sidebarOption {
  position: relative;
  font-size: 1.2rem;
  display: flex;
  color: white;
  height: 60px;
  line-height: 60px;
  border-radius: 0.5em;
  overflow: hidden;
  z-index: 110;
  transition: width 0.5s ease 0s, margin 0.25s ease 0s, padding 0.5s ease 0s, color 0.2s ease 0s, background-color 0.25s ease 0s;
}

.sidebarOption:hover {
  transition: width 0.5s ease 0s, margin 0.25s ease 0s, padding 0.5s ease 0s, color 0.2s ease 0s, background-color 0.25s ease 0s;
  background-color: white;
  color: black;
  cursor: pointer;
}

.sidebarOption.active {
  background-color: white;
  color: black;
}

.sidebar.close .sidebarOption {
  width: 60px;
  padding: 0em 1.05rem;
}

.sidebar.open .sidebarOption {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding: 0em 1.5em;
}

.sidebar.open .sidebarOption > .optionName {
  transition: opacity 0.5s ease 0.25s;
  opacity: 100%;
}

.sidebar.close .sidebarOption > .optionName {
  transition: opacity 0.5s ease 0s;
  opacity: 0%;
}

.sidebarOption > .icon {
  margin-right: 0.75em;
}

.sidebarOption > .icon > i {
  font-size: 1.6rem;
}

.dropdownContainer {
  position: relative;
  left: 0;
  height: 0;
  overflow: hidden;
  margin-left: calc((80px - 60px) / 2);
  margin-right: calc((80px - 60px) / 2);
  width: 60px;
  background-color: #333;
  border-top-width: 0;
  z-index: 105;
  transition: 
    width 0.5s ease 0s, 
    color 0.2s ease 0s, 
    height 0.5s ease 0s, 
    opacity 0.5s ease 0s, 
    padding 0.5s ease 0s,
    margin 0.25s ease 0s;
  line-height: 40px;
  color: white;
  opacity: 0%;
  border-radius: 0.5em;
}

.sidebar.open .dropdownContainer.ddExpand {
  transition: 
    width 0.25s ease 0s, 
    color 0.2s ease 0s, 
    height 0.5s ease 0s, 
    opacity 0.5s ease 0s, 
    padding 0.5s ease 0s,
    margin 0.25s ease 0s;
  opacity: 100%;
  padding-top: 1em;
  padding-bottom: 1em;
  width: 100%;
  margin: 0.5em 0 0.5em 0;
}

.sidebar.open .dropdownContainer {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.dropdownIcon > i::before {
  transition: transform 0.5s ease 0s;
}

.dropdownIcon > i.flip::before {
  transform: rotate(180deg);
}

.dd-indivOption {
  padding: 0em 1em;
  cursor: pointer;
}

.dd-indivOption:hover {
  background-color: #555;
}

i.bi.bi-box-arrow-left::before {
  margin-right: 1px;
  margin-left: -1px;
}