.topbar {
  position: fixed;
  top: 0;
  left: calc(60px + 1.6em);
  width: calc(100vw - 60px - 1.6em);
  padding: 0.8rem 4rem 0.8rem 3rem;
  height: 100px;
  background-color: white;
  z-index: 10000;
  box-shadow: 0px 0px 4px 0px #777;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topbar > div {
  width: calc(100% / 3);
}

.topbar .userDetails {
  font-size: 24px;
  line-height: 1em;
  margin-bottom: -2px;
}

.topbar .apotekAlamat {
  font-size: 18px;
}

.topbar .apotekLogo {
  flex-shrink: 0;
  text-align: center;
  height: 100%;
}

.topbar .apotekLogo img {
  width: 100%;
  height: 100%;
}

.topbar .currentUserSection {
  display: flex;
  align-items: center;
  flex-shrink: 1;
  margin-right: 1em;
  font-size: 24px;
  text-align: start;
}

.topbar .currentUserSection i {
  display: block;
  font-size: 44px;
  margin-right: 0.5em;
}

.topbar .version {
  flex-shrink: 1;
  margin-left: 1em;
  font-size: 24px;
  text-align: end;
}