.customButton {
  display: block;
  border: none;
  font-size: 18px;
  padding: 0.6em 1.8em;
  background-color: #005da4;
  color: white;
  border-radius: 0.3em;
  box-shadow: 0px 2px 3px 0px #777;
  cursor: pointer;
}

.customButton i.bi {
  position: relative;
  top: 1px;
  margin-right: 0.5em;
}

.customButton:disabled {
  background-color: #777;
  cursor: not-allowed;
}