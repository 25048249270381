.tableTabsFlex {
  position: relative;
  width: max-content;
  display: flex;
  gap: 0.25em;
}

.tableTab {
  position: relative;
  border: 0px solid var(--c-secondary);
  border-width: 1px 1px 0 1px;
  padding: 1em 2em;
  transition: background-color 0.2s ease;
  background-color: #fafafa;
  cursor: pointer;
}

.tableTab.selected {
  border-width: 1px 1px 0 1px;
  border-radius: 0.5em 0.5em 0 0;
  background-color: white;
}

.tableTab.selected ~ .tableTab {
  border-radius: 0.5em 0.5em 0 0;
  border-width: 1px 1px 0 1px;
}

.tableTab:has(~ .tableTab.selected) {
  border-radius: 0.5em 0.5em 0 0;
  border-width: 1px 1px 0 1px;
}

.tableTab::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  z-index: -10;
  border-radius: 0.5em 0.5em 0 0;
  box-shadow: 0px 0px 0px -3px #888;
}

.tableTab.selected::before {
  transition: box-shadow 0.2s ease;
  box-shadow: 0px 0px 12px -3px #888;
}

.tableTab::after {
  position: absolute;
  bottom: -1px;
  left: 0px;
  content: "";
  transition: background-color 0.2s ease;
  background-color: transparent;
  width: 100%;
  height: 1px;
  z-index: 100;
}

.tableTab.selected::after {
  background-color: white;
}

.tableTab:first-of-type {
  border-top-left-radius: 0.5em;
  border-top-width: 1px;
}

.tableTab:last-of-type {
  border-top-right-radius: 0.5em;
  border-top-width: 1px;
  border-right-width: 1px;
}

.tableTab:not(.selected) {
  z-index: 0;
}

.tableTabsFlex ~ * {
  background-color: white;
  border-top-left-radius: 0 !important;
}